import React from 'react';
import "../../../assets/css/expeditionInfo.css";
import expiditionImg from "../../../assets/image/expiditionImg.png";
import { Col, Row } from 'react-bootstrap';

const ExpeditionInfo = () => {
  return (
    <div className="custom-container container-fluid px-5">
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="info-text text-center">
            <h2>Facts</h2>
            <div className='expedition-main px-5'>
            <span className="font-expedition-title text-wrap">
              We’re a specialist climbing and expedition company <br />
              <span className="expedition-title">
                offering worldwide expeditions & expedition specific training
              </span>
            </span> 
            </div>
            <p className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
              Here are the some facts.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="facts-row mb-5  ">
        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">25K+</h3>
              <span className="card-expedition-title">Empowered by Adventure</span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">350+</h3>
              <span className="card-expedition-title">On Kilimanjaro</span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">1000+</h3>
              <span className="card-expedition-title">Treks </span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">98%</h3>
              <span className="card-expedition-title">Success Ratio @ Kilimanjaro</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExpeditionInfo;
