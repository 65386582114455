import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Container } from "react-bootstrap";
import { Whatsapp } from "react-bootstrap-icons";
import { FaWhatsapp } from 'react-icons/fa';

const PaymentInformation = ({ paymentData }) => {

    const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

    const [preference, setPreference] = useState('email');
    const [communicationType, setCommunicationType] = useState();
    const [communicationTypeId, setSommunicationTypeId] = useState();
    const userInfo = paymentData.data.guestUser || paymentData.data.user; 
    useEffect(() => {
        getcommunicationType()
        console.log("paymentData",paymentData.data.userTourTransientId)
        if(paymentData && paymentData.data.userTourTransientId){
            deleteuserTourTransient(paymentData.data.userTourTransientId)
        }
    }, []);


    const deleteuserTourTransient = async (userTourTransientId) => {
        try {
          const response = await fetch(`${BaseAPI}/tour/delete/user-tour-transient/${userTourTransientId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            throw new Error("Failed to delete the enterprise company.");
          }
          
        } catch (error) {
          console.error('Error deleting enterprise company:', error);
        
        }
      };
    const getcommunicationType = async () => {
        try {
            const response = await fetch(
                `${BaseAPI}/tour/all/communicationType`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setCommunicationType(data.data);
            if (data && data.length > 0) {
                setPreference(data[0].communicationType.replace("I prefer ", "").toLowerCase());
            }


        } catch (error) {
            console.error("Error fetching tour data:", error);
        }
    };

    const submitCommunication = async (e) => {
        e.preventDefault();
        const communicationData = {
            "userId": userInfo.guestUserId || userInfo.userId ,
            "scheduleId": paymentData.data?.scheduleId,
            "communicationTypeId": communicationTypeId
        }

        console.log("communication", communicationData);

        const response = await fetch(`${BaseAPI}/tour/save/tour/communication`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(communicationData),
        });
        if (!response.ok) {
            throw new Error("Failed to update tour");
        }
        const responseData = await response.json();
        console.log("response data", responseData)
        alert("User communication saved!!")

    }

    const handleChange = async (event) => {
        console.log("event.target.value", event.target.value)
        setPreference(event.target.value);
        setSommunicationTypeId(event.target.value)
    };
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const customerInfo = () => {
        setShowCustomerInfo(!showCustomerInfo)
        setpaymentInfo(false)
        setbookingInfo(false)
   
    };
    const [showPaymentInfo, setpaymentInfo] = useState(true);
    const paymentInfo = () => {
        setpaymentInfo(!showPaymentInfo)
        setShowCustomerInfo(false)
        setbookingInfo(false)
      

    };
    const [showBookingInfo, setbookingInfo] = useState(false);
    const bookingInfo = () => {
        setbookingInfo(!showBookingInfo)
        setShowCustomerInfo(false)
        setpaymentInfo(false)
    };



    return (


        <div>
            <row className="justify-content-center eureka-navbar-padding ">


                <div className=''>
                    <div className="d-flex justify-content-between checkout-container-main align-items-center p-2 information-background" role="button">
                        <span className="font-medium-title font" onClick={customerInfo}>Customer Information</span>
                        <span className=" ">Step 1 of 3</span>
                    </div>
                    {showCustomerInfo && (
                        <div className="box-container-main" >
                            <div className="mt-2 ">
                                <p className="text-gray-700 dark:text-gray-400 font-feature-tour">Email: {userInfo?.email}</p>
                                <p className="text-gray-700 dark:text-gray-400 font-feature-tour">Full Name: {userInfo?.firstName}</p>
                                <p className="text-gray-700 dark:text-gray-400 font-feature-tour">Phone Number: {userInfo?.mobileNumber}</p>
                            </div>
                        </div>

                    )}
                </div>

            </row>

            <row className="justify-content-center eureka-navbar-padding">
                <div className=''>
                    <div className="d-flex justify-content-between checkout-payment-container align-items-center p-2 mt-2 information-background" role="button">
                        <span className="font-medium-title font" onClick={paymentInfo}>Payment Information</span>
                        <span className=" ">Step 2 of 3</span>
                    </div>
                    {showPaymentInfo && <div className="box-container-main" >
                        <p className='text-gray-700 dark:text-gray-400 font-feature-tour mt-2'>Your payment was successful!</p>
                        <div>
                            <p className="text-gray-700 dark:text-gray-400 font-feature-tour">TransactionId: {paymentData.data.transactionId}</p>
                            <p className="text-gray-700 dark:text-gray-400 font-feature-tour">Paid Amount: ${paymentData.data.paidAmount}</p>

                        </div>
                    </div>}
                </div>

            </row>



            <row className="justify-content-center eureka-navbar-padding">


                <div className=''>
                    <div className="d-flex justify-content-between  checkout-payment-container  align-items-center p-2 mt-2 information-background" role="button">
                        <span className="font-medium-title font" onClick={bookingInfo}>Booking Questions</span>
                        <span className=" ">Step 3 of 3</span>
                    </div>
                    {showBookingInfo && <div className="box-container-main" >
                        <p className='text-gray-700 dark:text-gray-400 font-feature-tour  mt-2'>If u have any questions, select below</p>
                        <div className="contact-preference ">

                            <div className="contact-preference">
                                {communicationType?.map((type) => (
                                    <label className="option text-gray-700 dark:text-gray-400 font-feature-tour" key={type.communicationTypeId}>
                                        <input
                                            type="radio"
                                            name="contact-preference"
                                            value={type.communicationTypeId}
                                            checked={preference === type.communicationTypeId}
                                            onChange={handleChange}
                                        />
                                        {type.communicationType} 
                                       
                                        {type.communicationType === "I prefer WhatsApp" && <span className="ms-2"><FaWhatsapp  color="#25D366"/></span>}
 
                                    </label>

                                ))}                                 

                                <div className="text-end mb-1">
                                    <Button className="view-more-btn" onClick={submitCommunication}>
                                        SEND
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>}
                </div>

            </row>






        </div>
    );
};

export default PaymentInformation;
