import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/footer.css"; // Importing external CSS file
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import FacebookImg from "../../assets/image/Group.png";
import InstagramImg from "../../assets/image/Group (1).png";
import PinterestImg from "../../assets/image/Group (2).png";
import TwitterImg from "../../assets/image/Group (3).png";
import YouTubeImg from "../../assets/image/Group (4).png";
import LinkedInImg from "../../assets/image/Group (5).png";

const Footer = () => {
  const BaseAPI = process.env.REACT_APP_PEEK_SERVER_ENDPOINT;
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  const [isValidLength, setIsValidLength] = useState(true);

  const privacyPolicyUrl = "/privacy-policy";
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setMessage(value);
    // Validate length
    if (value.length > 255) {
      setIsValidLength(false);
    } else {
      setIsValidLength(true);
    }
  };

  const handleSubmit = (event) => {};
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailValid(validateEmail(event.target.value));
  };

  const submitContactUs = async (event) => {
    event.preventDefault();
    try {
      // Handle form submission logic
      if (!emailValid) {
        toast.error("Enter Valid Email");
        return;
      }
      if (!isPrivacyPolicyChecked) {
        alert("Please checked privacy policy");
        return;
      }
      if (isValidLength) {
        console.log("Message:", message);
        // Proceed with submission logic

        console.log("Message:", message);
      } else {
        console.error("Message exceeds 255 characters");
        // Handle error state or display message
      }
      const data = {
        email: email,
        message: message,
      };

      const contactResponse = await fetch(`${BaseAPI}/rest/auth/contact-us`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      const contactResponseData = await contactResponse.json();
      if (contactResponseData) {
        toast.success("Data Saved. Thank You For Contacting Us!!!");
        setShow(false);
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(error.message);
    }
  };
  const handlePrivacyPolicyChange = () => {
    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked);
  };

  return (
    <>
      <footer className="text-light py-4 footer-container">
        <div className="container">
          <Row className="footer-main mt-4">

            <Col md={3} className="mb-2 ms-4 main-address">
            <div className="border-end border-md-end-0 col-9">
              <div className="footer-info mt-2 mb-4">
                <span className="mb-5 font-title">
                  360 Explorer Inc. Delaware, USA <br />
                  +91-7770000205 | 7770000206
                </span>
              </div>
              </div>
            </Col>

            <Col md={3} className="me-4 mb-2 ">
            <div className="border-end border-md-end-0 col-9">
              <div className="footer-info mt-2 mb-4">
                <span className="mb-5 font-title">
                  360 Explorer LLP. MAHARASHTRA INDIA <br />
                  +91-7770000205 | 7770000206
                </span>
              </div>
              </div>
            </Col>

            <Col md={3} className="me-4 mb-2">
            <div className="border-end border-md-end-0 col-9">

              <div className="footer-info mt-2 mb-4">
                <span className="mb-5 font-title">
                  360 Explorer Inc. Alberta, Canada <br />
                  +91-7770000205 | 7770000206
                </span>
              </div>
              </div>
            </Col>

            <Col md={2} className="mb-2 ">
            
              <h6 className="mb-2 font-title">SUPPORT</h6>
              <div className="mt-1">
                <ul className="list-unstyled footer-links">
                  <li className="font-title">
                    <Link to="#" onClick={handleShow}>
                      Contact Us
                    </Link>
                  </li>
                  <li className="font-title">
                    <Link to="/faq">Frequently Asked Questions</Link>
                  </li>
                  <li className="font-title">
                    <Link to="">info@eurekatrip.com</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="social-icons justify-content-center mb-3">
              <a
                href=" https://www.facebook.com/eurekatripofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="me-2 social-icon"
                  src={FacebookImg}
                  alt="Facebook"
                />
              </a>
              <a
                href=" https://www.instagram.com/ieurekatrip/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="me-2 social-icon"
                  src={InstagramImg}
                  alt="Instagram"
                />
              </a>

              <a
                href="https://www.linkedin.com/company/eurekatrip-com/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="me-2 social-icon"
                  src={LinkedInImg}
                  alt="LinkedIn"
                />
              </a>
            </div>
          </Row>

          <Row className="justify-content-center">
            <Col className="text-center footer-info font-title me-5">
              <span> © 2024 360 Explorer Inc.</span>
              <span className="ms-2">
                All rights reserved. |{" "}
                <Link
                  to="/privacy-policy"
                  className="text-decoration-none text-white"
                >
                  Privacy
                </Link>{" "}
                |{" "}
                <Link to="/terms" className="text-decoration-none text-white">
                  Terms
                </Link>{" "}
                | Image Attribution
              </span>
            </Col>
          </Row>
        </div>
      </footer>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-2 contactus-title ms-4 fw-bold">
            Contact Us
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card.Body className="contact-us-main">
            <Card.Text className="text-center contactus-title">
              Please write your message and contact information below. We will
              respond as soon as possible.
            </Card.Text>
            <Form>
              <Row className="mb-3">
                <Col>
                  {/* <Form.Control
                    className="contactus-title"
                    type="email"
                    placeholder="Enter your email"
                    style={{ borderRadius: "14px" }}
                  /> */}
                  <Form.Control
                    onChange={handleEmailChange}
                    value={email}
                    isInvalid={!emailValid}
                    className="contactus-title"
                    type="email"
                    placeholder="Enter your email"
                    style={{ borderRadius: "14px" }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Control
                  className="contactus-title"
                  as="textarea"
                  placeholder="Type your message here!"
                  rows={3}
                  style={{
                    borderRadius: "18px",
                    marginLeft: "10px",
                    width: "96%",
                  }}
                  value={message}
                  onChange={handleChange}
                  isInvalid={!isValidLength} // Bootstrap's isInvalid prop for styling
                />
                <Form.Control.Feedback type="invalid">
                  Message cannot exceed 255 characters.
                </Form.Control.Feedback>
              </Row>
              {/* <Row className="mb-3">
                <Col>
                  <Form.Control type="tel" placeholder="Enter your phone number" />
                </Col>
              </Row> */}
              <Row className="mb-3 contactus-title">
                <Col>
                  {/* <Form.Check
                    type="checkbox"
                    id="privacyPolicy"
                    label={
                      <span>
                        I confirm that I've read and agree to{" "}
                        <Link
                          to="/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
 </Link>
                        .
                      </span>
                    }
                  />

                      </Link>.
                  </span>} */}
                  {/* /> */}
                  <Form.Check
                    type="checkbox"
                    id="privacyPolicy"
                    checked={isPrivacyPolicyChecked}
                    onChange={handlePrivacyPolicyChange}
                    label={
                      <span>
                        I confirm that I've read and agree to{" "}
                        <Link
                          to="/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </span>
                    }
                    isInvalid={
                      !isPrivacyPolicyChecked && !emailValid && !isValidLength
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    You must agree to the privacy policy before submitting.
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* <Button
                    className="contacts-submit"
                    variant="dark"
                    type="submit"
                    block
                    style={{ borderRadius: "14px" }}
                  /> */}

                  <Button
                    className="contacts-submit"
                    onClick={submitContactUs}
                    variant="dark"
                    type="submit"
                    block
                    style={{ borderRadius: "14px" }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Footer;
