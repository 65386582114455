import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import "../../../assets/css/checkout.css";
import { useAuth } from "../../../context/Authcontext";
import "react-phone-input-2/lib/style.css";

import { useLocation, useNavigate } from "react-router-dom";

import LoginPopup from "../../Login/LoginPopup";
import PhoneInput from "react-phone-input-2";
const CustomerInformation = ({
  formData,
  handleChange,
  handleSubmit,
  handleAdvancePaymentSubmit,
  selectedSchedule,
}) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const BaseApiUser = process.env.REACT_APP_PEEK_SERVER_ENDPOINT;

  const userData = JSON.parse(localStorage.getItem("user"));
  const [handleLoginPopup, setHandleLoginPopup] = useState(false);
  const [TourTransient, setTourTransient] = useState(false);

  const navigate = useNavigate();
  const { user, logout } = useAuth();
  // const [userData, setUserData] = useState({});
  const [email, setEmail] = useState(userData?.email);
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  // const [allCountry, setAllCountry] = useState([]);

  useEffect(() => {
    // getAllCountry();
    if (userData) {
      setEmailValid(true);
      setPhoneValid(true);
    }
    const timeoutId = setTimeout(() => {
      if (emailValid && !TourTransient) {
        logDataSubmit(email);
        setTourTransient(true);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [email, emailValid, TourTransient]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    const isValid = validateEmail(email);
    setEmailValid(isValid); // Update validity state

    console.log("validate ", validateEmail(email));
    if (validateEmail(email)) {
      logDataSubmit(email); // Call logDataSubmit directly when email is valid
    }
    handleChange(e);
  };

  const logDataSubmit = async (email) => {
    console.log("userdata", userData);
    // e.preventDefault();

    const logData = {
      email: email,
      scheduleId: selectedSchedule?.scheduleId,
      userId: userData?.userId || "",
    };
    const response = await fetch(`${BaseAPI}/tour/save/user-transient`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(logData),
    });

    const responseData = await response.json();
    console.log("responseData======>", responseData);
    if (responseData) {
      setTourTransient(true);
    }
  };

  // const getAllCountry = async () => {
  //   try {
  //     const response = await fetch(`${BaseApiUser}/rest/auth/all/countries`);

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     console.log(" contry data==>", data);
  //     setAllCountry(data.data);
  //   } catch (error) {
  //     console.error("Error fetching themes:", error);
  //   }
  // };

  const handlePhoneChange = (phone) => {
    setPhoneValid(validatePhoneNumber(phone));
    handleChange({ target: { name: "mobileNumber", value: phone } });
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.endsWith(".com");
  };

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d+$/.test(phoneNumber);
  };

  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const handleLoginSuccess = () => {
    closeLoginForm();
    navigate("/");
  };
  const closeLoginForm = () => {
    setHandleLoginPopup(false);
    setShowRegisterForm(false);
  };

  const toggleLoginFormVisibility = () => {
    setHandleLoginPopup(true);
    setShowRegisterForm(false);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("emailValid", emailValid);
    console.log("emailValid", phoneValid);

    if (!emailValid || !phoneValid) {
      return;
    }

    handleSubmit(e);
  };
  const handleAdvancePayment = (e) => {
    e.preventDefault();
    console.log("emailValid", emailValid);
    console.log("emailValid", phoneValid);

    if (!emailValid || !phoneValid) {
      return;
    }

    handleAdvancePaymentSubmit(e);
  };
  return (
    <div>
      <row className="justify-content-center eureka-navbar-padding">
        <div className="">
          <div className="d-flex justify-content-between checkout-container-main align-items-center p-2 information-background">
            <span className="font-medium-title font">Customer Information</span>
            <span className=" ">Step 1 of 3</span>
          </div>
          <div className="box-container-main">
            <p className=" mt-2 text-customer text-gray-700 dark:text-gray-400 font-feature-tour">
              We’ll use this information to send confirmation and updates for
              your booking
            </p>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-medium">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleEmailChange}
                  isInvalid={!emailValid}
                />
                {!emailValid && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    Please enter a valid email.
                  </div>
                )}
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicFullName">
                    <Form.Label className="font-medium">Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter full name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {/* <Col>
                                    <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                                        <Form.Label className='font-medium'>Phone Number</Form.Label>
                                        
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter phone number"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handlePhoneChange}
                                            isInvalid={!phoneValid}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid phone number.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    
                                </Col> */}
                <Col sm={7}>
                  <Form.Group className="mb-3" >
                    <Form.Label className="tour-form-label">Mobile Number</Form.Label>
                    <PhoneInput 
                      country={"in"} // Default country
                      value={formData.mobileNumber}
                      onChange={handlePhoneChange}
                      inputProps={{
                        name: "mobileNumber",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="form-control"
                    />
                    {!phoneValid && (
                      <div style={{ color: "red", fontSize: "small" }}>
                        Please enter a valid phone number.
                      </div>
                    )}
                  </Form.Group>
                </Col>
                
              </Row>

              {!userData && (
                <p className="text-gray-700 dark:text-gray-400 font-feature-tour mt-2">
                  Already have an account?{" "}
                  <span
                    role="button"
                    onClick={toggleLoginFormVisibility}
                    className="text-primary border-bottom border-primary"
                  >
                    Log in here{" "}
                  </span>
                  for a faster checkout experience!
                </p>
              )}
              <div className="text-center font-medium">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleFormSubmit}
                >
                  Full Payment
                </Button>
                <Button
                  className="ms-2"
                  variant="primary"
                  type="submit"
                  onClick={handleAdvancePayment}
                >
                  Advance Payment
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </row>

      {!user && (
        <LoginPopup
          onLoginSuccess={handleLoginSuccess}
          showLoginPopup={handleLoginPopup}
          handleLoginClose={closeLoginForm}
          showRegisterForm={showRegisterForm}
          setShowRegisterForm={setShowRegisterForm}
          setHandleLoginPopup={setHandleLoginPopup}
        />
      )}

      <div className="d-flex justify-content-between  align-items-center p-2 information-background  text-secondary  ">
        <span className="font-medium-title font ">Payment Information</span>
        <span className=" ">Step 2 of 3</span>
      </div>
      <div className="d-flex justify-content-between  align-items-center p-2 mt-2 information-background  text-secondary">
        <span className="font-medium-title font">Booking Questions</span>
        <span className=" ">Step 3 of 3</span>
      </div>
    </div>
  );
};

export default CustomerInformation;
