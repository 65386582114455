import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "../../../assets/css/featuretour.css";
import { useNavigate } from "react-router-dom";
import {
  ArrowBarRight,
  Bookmark,
  GeoAlt,
  ArrowLeft,
  ArrowRight,
  BookmarkFill,
} from "react-bootstrap-icons";
import NavbarHome from "../common/Navbar.js";

import axios from "axios";
import starImg from "../../../assets/image/Star 13.png";

const MyWhishlist = () => {

  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const BaseAPIAuth =process.env.REACT_APP_PEEK_SERVER_ENDPOINT;
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));

  const [featureTours, setFeatureTours] = useState([]);
  const [wishLists, setWishlists] = useState([]);
  const [displayedTours, setDisplayedTours] = useState([]);
  const [wishlist, setWishlist] = useState(new Set());

  useEffect(() => {
    const fetchTours = async () => {
      try {
       const response = await fetch(`${BaseAPI}/tour/favTours/user/${userData.userId}`);
        // const endpoint = userData?.userId
        //   ? `/tour/all/featureTours/user/${userData.userId}`
        //   : `/tour/all/special-tours/bmm`;
        // const response = await fetch(`${BaseAPI}${endpoint}`);

        if (response.ok) {
          const data = await response.json();
          const toursData = await Promise.all(
            data.data.map(async (tour) => {
              if (
                tour.location &&
                tour.location.latitude &&
                tour.location.longitude
              ) {
                const { city, country } = await fetchLocationDetails(
                  tour.location.latitude,
                  tour.location.longitude
                );
                return { ...tour, city, country };
              } else {
                return {
                  ...tour,
                  city: "Location not available",
                  country: "Country not available",
                };
              }
            })
          );
          setFeatureTours(toursData);
          updateWishlist(toursData);
        } else {
          console.error("Failed to fetch themes:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchTours();
  }, []);


  const updateWishlist = (tours) => {
    const newWishlist = new Set(wishlist);
    tours.forEach((tour) => {
      if (tour.isFavourite) {
        newWishlist.add(tour.tourId);
      }
    });
    setWishlist(newWishlist);
  };
  
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.post(`${BaseAPI}/tour/save/favTour`);
        if (response.status === 200) {
          setWishlists(response.data);
        } else {
          console.error("Failed to fetch wishlist:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    fetchWishlist();
  }, []);


  const handleCardClick = (tourId) => {
    navigate(`/tour/${tourId}`);
  };


  const fetchLocationDetails = async (latitude, longitude) => {
    const url = ` https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.locality) {
        const city = data.locality || "City not available";
        const country = data.countryName || "Country not available";
        return { city, country };
      } else {
        console.error("No results found");
        return { city: "Unknown", country: "Unknown" };
      }
    } catch (error) {
      console.error("Failed to fetch location details:", error);
      return { city: "Error", country: "Error" };
    }
  };

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
      alert("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist: ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
        toast.success("Added to your Wishlist");
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
      alert("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => {
          const newWishlist = new Set(prevWishlist);
          newWishlist.delete(tourId);
          return new Set(newWishlist);
        });
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleViewMore = () => {
    const nextTours = featureTours?.slice(
      displayedTours?.length,
      displayedTours?.length + 4
    );
    setDisplayedTours([...displayedTours, ...nextTours]);
  };

  const hasMoreTours = displayedTours?.length < featureTours?.length;

  

  const directionButtons = (direction) => {
    return (
      <span>
        {direction === "Next" ? (
          <ArrowRight className="rightArrow" />
        ) : (
          <ArrowLeft className="rightArrow" />
        )}
      </span>
    );
  };

  useEffect(() => {
    if (featureTours?.length > 0) {
      setDisplayedTours(
        featureTours?.slice(0, Math.min(4, featureTours?.length))
      );
    }
  }, [featureTours]);

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    navigate(`/tour/${tourId}`, {
      state: {
        tourId,
      },
    });
  };

  return (
    <>
    <div>
    <NavbarHome />
    </div>
    <Container fluid className="custom-container h-auto  px-5 " style={{marginTop:"70px"}}>
      <h1 className="text-start feature-heading"> My Wishlist </h1>
      <Row>
        {displayedTours.map((tour, idx) => (
          <Col xs={12} sm={6} md={4} lg={3} key={idx}>
            <Card className="h-100">
              <div>
                <div className="carousel-hover">
                  <Carousel
                    className="carousel-overlay"
                    interval={null}
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Previous")}
                  >
                    {tour.tourAssets.map((asset, assetIdx) => (
                      <Carousel.Item
                        key={assetIdx}
                        className="card-img-container"
                      >
                        <img
                          onClick={() => handleTourCardClick(tour)}
                          className=" "
                          src={asset.assetUrl}
                          alt={`Slide ${assetIdx}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <img
                    onClick={() => handleTourCardClick(tour)}
                    variant="top"
                    src={tour.tourAssets[0].assetUrl}
                    className=" card-img-container"
                  />
                  <div className="overlay-text">
                    {tour.tourTheme[0].theme.themeName}
                  </div>
                  <div className="bookmark-icon">
                    {wishlist.has(tour.tourId) ? (
                      <BookmarkFill
                        onClick={() => removeFromWishlist(tour.tourId)}
                        style={{ color: "white" }}
                      />
                    ) : (
                      <Bookmark onClick={() => addToWishlist(tour.tourId)} />
                    )}
                  </div>
                </div>

                <div className="d-flex flex-column mt-2">
                  <span className="font-medium-title feature-title">
                    {tour.title}
                  </span>
                  <Row>
                    <div>
                      <div className="d-inline z-30 font-feature-tour">
                      From <span className="me-2">$</span> {tour.price}
                      </div>
                      <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                        Duration {tour.duration} {tour.durationTime}
                      </div>
                    </div>
                  </Row>

                  <Row className="mb-5">
                    <div className="flex justify-between gap-3 mt-auto col-12 ">
                      <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                        <span className="black-star">
                          <img className="starImg" src={starImg} alt="star" />
                        </span>
                        <span className="ms-2">5.0</span>
                        <span className="dot"></span>
                        <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                          3 Ratings
                        </span>
                      </div>

                      <div
                        role="button"
                        className="float-end col-lg-5 col-6  font-medium location-text text-wrap"
                        onClick={() => handleTourCardClick(tour)}
                      >
                        <span className="font-medium ">
                          <GeoAlt className="text-muted me-1" />
                          {/* {tour.city}, {tour.country} */}
                          Pune, India
                        </span>
                      </div>
                    </div>
                  </Row>
                </div>

              </div>
            </Card>
          </Col>
        ))}
        <ToastContainer />
      </Row>

      {hasMoreTours && (
      <div className="text-end mb-2">
        <Button className="view-more-btn" onClick={handleViewMore}>
          VIEW MORE
        </Button>
      </div>
    )}
      
    </Container>
    </>
  );
};

export default MyWhishlist;
